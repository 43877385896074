<template>
  <div class="newsdetailspage">
    <Head />
    <TitleBg />
    <div class="maincontent">
      <div class="lastPage">
        <a @click="handleBackClick('/newsdetails')">
          <img src="@/assets/image/pc/箭头.png">
          <p>更多新闻</p>
        </a>
      </div>
      <div class="mainkind" :style="{'background':kindColor}">
        <p>{{ kind }}</p>
      </div>
      <div class="maindate">
        <p>{{ data.createTime | dateFilters }}</p>
      </div>
      <div class="maintitle">
        <p>{{ data.title }}</p>
      </div>
      <div class="content" v-html="data.content">
      <!-- <div class="content"> -->
        <!-- <img src="@/assets/image/pc/虎年第一招.jpg"> -->
        <!-- <p>为了跟玩家进行更多的交流，《青空之刃》项目组在2021年3月13-14日这样“浪漫”的日子进 行了一次线下闭门前哨测试活动。为了跟玩家进行更多的交流，《青空之刃》项目组在2021年3月13-14日这样“浪漫”的日子进 行了一次线下闭门前哨测试活动。为了跟玩家进行更多的交流，《青空之刃》项目组在2021年3月13-14日这样“浪漫”的日子进 行了一次线下闭门前哨测试活动。。</p> -->
      </div>
    </div>
    <Floor />
  </div>
</template>
<script>
import Head from '@/views/Head'
import Floor from '@/views/Floor'
import TitleBg from '@/views/NewsDetails/TitleBg'
import { reqGetContent, reqGetSetting } from '@/api/news'

export default {
  name: 'NewsDetailsPage',
  components: {
    Head,
    Floor,
    TitleBg
  },
  filters: {
    dateFilters(str) {
      const date = new Date(str).format('yyyy-MM-dd')
      return date
    }
  },
  data() {
    return {
      data: {},
      videoList: [],
      kind: "",
      kindColor: "",
      querySetting: {
        key: "article_type"
      }
    }
  },
  async created() {
    await this.getContent()
    this.getSetting()
  },
  methods: {
    async getContent() {
      const id = this.$route.query.id
      const res = await reqGetContent(id)
      this.data = res.data
    },
    async getSetting() {
      const resSetting = await reqGetSetting(this.querySetting)
      // console.log(resSetting.data)
      // console.log(resSetting.data.length)
      const resSettingJson = JSON.parse(resSetting.data)
      console.log(resSettingJson)
      // eslint-disable-next-line no-array-constructor
      var mapList = new Array();
      for (var i = 0; i < resSettingJson.length; i++) {
        // console.log(settingList)
        if (resSettingJson[i].key === "gg.com") {
          mapList = resSettingJson[i].value
          break;
        }
      }
      console.log(mapList)
      for (var j = 0; j < mapList.length; j++) {
        if (mapList[j].value === this.data.tag) {
          this.kind = mapList[j].label
          console.log(this.kind)
          this.kindColor = mapList[j].color
          console.log(this.kindColor)
        }
      }
      return true
    },
    handleBackClick(path) {
      this.$router.push({
        path: path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.newsdetailspage {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .maincontent {
    position: relative;
    background: #e7e6e3;
    padding-bottom: 100px;
    // height:0;
    .lastPage {
      position: absolute;
      top: 110px;
      left: 146px;
      img {
        cursor: pointer;
        position: absolute;
        width: 8px;
        height: 14px;
      }
      p {
        width: 60px;
        cursor: pointer;
        margin-left: 19px;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
      }
    }
    .mainkind {
      position: absolute;
      width: 52px;
      height: 22px;
      background: #d0383c;
      margin-top: 109px;
      margin-left: 539px;
      p {
        text-align: center;
        margin-top: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
      }
    }
    .maindate {
      position: absolute;
      left: 602px;
      top: 111px;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #21211d;
      }
    }
    .maintitle {
      position: relative;
      width: 850px;
      // top: 141px;
      // left: 539px;
      margin: 0 auto;
      padding-top: 141px;
      p {
        font-size: 42px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2b2a29;
      }
    }
    .content {
      position: relative;
      // top: 185px;
      // left: 544px;
      width: 850px;
      margin: 0 auto;
      padding-top: 44px;
      img {
        width: 100%;
      }
      p {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #545454;
        line-height: 39px;
      }
    }
  }
}
</style>

<style lang="scss">
.newsdetailspage {
  .content {
    position: relative;
    // top: 185px;
    // left: 544px;
    width: 850px;
    margin: 0 auto;
    padding-top: 44px;
    img {
      width: 100%;
      height: 100%;
    }
    p {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #545454;
      line-height: 39px;
      // padding: 0 5px;
    }
  }
}
video{
  z-index:1;
  height:100%;
  min-width: 1000px;
  max-width: 1200px;
  margin: 0 auto;
}
</style>
